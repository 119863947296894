import React, { useEffect } from 'react';
import Loading from 'blocks/Loading';
import CookieBar from 'components/CookieBar';
import DataLayer from 'containers/DataLayer';
import MarketStyle from 'containers/MarketStyle';
import * as COOKIE_BAR_NODES from 'djedi-nodes/cookie-bar';
import * as NODES from 'djedi-nodes/generic';
import { djedi, ForceNodes, NodeContext } from 'djedi-react';
import moment from 'moment';
import { NextApiRequest, NextComponentType, NextPageContext } from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import nextCookies from 'next-cookies';
import { CSRF_COOKIE_NAME, DEFAULT_MARKET, OUS_MARKETS } from 'utils/constants';

import { EnhancedPageContext } from '../../types/EnhancedPageContext';
import Api from '../api';
import { ApiProvider } from '../contexts/ApiContext';
import { UserState } from '../reducers/user';
import { makeCookies } from '../utils';
import { Market } from '../utils/constants';

import 'wicg-inert';
import 'reset.css';
import '../global.css';
import 'react-day-picker/src/style.css';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/en-gb';

const LANGUAGES = ['nb', 'en', 'sv'];
const COMPOUND_LANGUAGES = LANGUAGES.map(lang =>
  Object.keys(Market).map(market => `${lang}-${Market[market]}`),
).flat();
const DEFAULT_COMPOUND = COMPOUND_LANGUAGES[0];
moment.locale(DEFAULT_COMPOUND.split('-')[0]);

const frontendApi = typeof window !== 'undefined' ? Api() : undefined;

const CustomApp = ({ Component, pageProps = {} }: AppProps) => {
  moment.locale(pageProps.language);
  if (pageProps.nodes != null) {
    djedi.addNodes(pageProps.nodes);
  }
  djedi.injectAdmin();

  const isOUSSite = OUS_MARKETS.includes(pageProps.currentMarket);

  useEffect(() => {
    const eventAddAccessibility = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.add('accessibility-mode');
      }
    };
    const eventRemoveAccessibility = () => {
      document.body.classList.remove('accessibility-mode');
    };

    document.addEventListener('keydown', eventAddAccessibility);
    document.addEventListener('mousedown', eventRemoveAccessibility);

    return () => {
      document.removeEventListener('keydown', eventAddAccessibility);
      document.removeEventListener('mousedown', eventRemoveAccessibility);
    };
  }, []);

  return (
    <>
      <MarketStyle />
      <NodeContext.Provider value={`${pageProps.language}-${pageProps.currentMarket}`}>
        <DataLayer
          user={pageProps.user}
          currentMarket={pageProps.currentMarket}
          language={pageProps.language}
        >
          <ApiProvider value={pageProps.api || frontendApi}>
            <Component {...pageProps} />
          </ApiProvider>
          <Loading />
          <ForceNodes>
            {NODES}
            {COOKIE_BAR_NODES}
          </ForceNodes>
        </DataLayer>
      </NodeContext.Provider>

      {/* CookieBar handles the loading of GTM dynamically as it depends on consent */}
      {!isOUSSite && <CookieBar />}
    </>
  );
};

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

// WHITELABEL / MARKET
const currentMarket = Market[publicRuntimeConfig.MARKET] || DEFAULT_MARKET;

// DJEDI
djedi.options.baseUrl = `${
  serverRuntimeConfig.API_URL ? serverRuntimeConfig.API_URL : publicRuntimeConfig.API_URL
}/_/bananas/djedi/cms/api`;
djedi.options.languages.default = DEFAULT_COMPOUND;
djedi.options.languages.additional = COMPOUND_LANGUAGES.filter(lang => lang !== DEFAULT_COMPOUND);

// TODO: Make this dynamic
djedi.options.uri.defaults.scheme = 'i18n';
djedi.options.fetch = (url: string, options = { headers: {} }) => {
  const auth = Buffer.from(
    `${serverRuntimeConfig.BASIC_AUTH_USER}:${serverRuntimeConfig.BASIC_AUTH_PASSWORD}`,
  ).toString('base64');

  // Add custom market header to djedi requests
  options.headers = {
    Market: currentMarket,
    ...options.headers,
  };

  return fetch(
    url,
    serverRuntimeConfig.BASIC_AUTH_USER == null || serverRuntimeConfig.BASIC_AUTH_PASSWORD == null
      ? options
      : {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Basic ${auth}`,
          },
        },
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ExtendedNextPage<P = {}, IP = P> = NextComponentType<EnhancedPageContext, IP, P>;

export interface EnhancedAppInitialProps extends NextPageContext {
  Component: ExtendedNextPage;
  ctx: NextPageContext;
  req: NextApiRequest;
  nodes: Record<string, unknown>;
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

CustomApp.getInitialProps = async (appContext: EnhancedAppInitialProps) => {
  const { Component, ctx, req = { headers: {} } } = appContext;
  const cookies = nextCookies(ctx);
  const language = ctx.locale;
  moment.locale(language);

  const api = Api({
    headers: {
      ...(req.headers ? req.headers : {}),
      Cookie: makeCookies(cookies),
      'X-CSRFTOKEN': cookies[CSRF_COOKIE_NAME],
      // TODO: Make this dynamic
      // Add custom market header to API requests
      Market: currentMarket,
      'Accept-Language': language,
    },
  });

  let loggedIn = false;
  let user: undefined | UserState = undefined;

  // We're polling the user endpoint for every request made as a logged in user,  this might need looking into.
  // ALternate solution: If the response
  if (cookies.sessionid) {
    try {
      user = await api.me();
      loggedIn = !!user;
    } catch {
      loggedIn = false;
    }
  }
  let pageProps = {};

  if (Component.getInitialProps != null) {
    const enhancedPageContext: EnhancedPageContext = {
      ...ctx,
      api,
      user,
      currentMarket,
      language,
    };

    pageProps = await Component.getInitialProps(enhancedPageContext);
  }

  await djedi.prefetch().catch((error: Record<string, unknown>) => {
    if (error.response == null) {
      error.noResponse = true;
    }
    throw error;
  });

  const nodes = djedi.track();
  return { pageProps: { language, ...pageProps, loggedIn, user, nodes, currentMarket } };
};

export default CustomApp;
