import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import CheckedBox from '../../../icons/check_box.svg';
import EmptyCheckbox from '../../../icons/check_box_outline_blank.svg';
import styles from './Checkbox.module.css';

interface Props extends FieldRenderProps<string | number, HTMLInputElement> {
  className?: string;
  classNames?: Partial<Record<'checkedCheckbox' | 'emptyCheckbox' | 'checkboxWrapper', string>>;
  label?: string | React.ReactNode;
  disabled?: boolean;
  labelPosition?: 'before' | 'after';
}

/**
 * Checkbox for use with FinalForm.
 * if FinalForm is not used, props for the Checkbox has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} type="checkbox" render={Checkbox}/>
 *
 *
 * @param className "className"
 * @param label label
 * @param disable the checkbox
 * @param labelPosition whether to render the label befor the checkbox, or after
 */

const Checkbox: React.FC<Props> = ({
  className,
  classNames,
  labelPosition = 'after',
  disabled,
  input,
  label,
  meta,
  ...props
}) => {
  const Label = (
    <span
      className={cx(styles.label, {
        [styles.labelBefore]: labelPosition === 'before',
      })}
    >
      {label}
    </span>
  );
  return (
    <label className={cx(styles.root, className)}>
      <input
        type="checkbox"
        className={cx(styles.input)}
        disabled={disabled}
        aria-disabled={disabled}
        {...input}
        {...props}
      />
      {label && labelPosition == 'before' && Label}
      <span aria-hidden="true" className={cx(styles.fauxCheckbox, classNames?.checkboxWrapper)}>
        <EmptyCheckbox
          className={cx(styles.empty, classNames?.emptyCheckbox)}
          fill="currentColor"
        />
        <CheckedBox
          className={cx(styles.checked, classNames?.checkedCheckbox)}
          fill="currentColor"
        />
      </span>
      {label && labelPosition == 'after' && Label}
      {meta.error && meta.error}
    </label>
  );
};

export default Checkbox;
