import React from 'react';
import { md, Node } from 'djedi-react';

const pageName = 'home';

export const STEPS = [
  {
    TITLE: <Node uri={`${pageName}/steps/step-1/title`}>NIPT-skolen</Node>,
    CONTENT: (
      <Node uri={`${pageName}/steps/step-1/content.md`}>{md`
        Gå NIPT-skolen når du vil. Den er helt gratis og gir deg informasjon så du kan gjøre et informert valg. Du logger trygt inn med din BankID.
      `}</Node>
    ),
    CTA_TEXT: <Node uri={`${pageName}/steps/step-1/cta-text`}>Kom i gang med NIPT-skolen</Node>,
    CTA_LINK: <Node uri={`${pageName}/steps/step-1/cta-link`}>/#login</Node>,
  },
  {
    TITLE: <Node uri={`${pageName}/steps/step-2/title`}>Besøk en klinikk i nærheten av deg</Node>,
    CONTENT: (
      <Node uri={`${pageName}/steps/step-2/content.md`}>{md`
NIPT tas tidligst i uke 10+0 og helt opp til uke 40. De fleste tar NIPT i uke 11-13. Før NIPT har du rett på informasjon og veiledning. En enkel ultralyd gjøres før selve blodprøven tas.
      `}</Node>
    ),
    CTA_TEXT: <Node uri={`${pageName}/steps/step-2/cta-text`}>Book tid for NIPT-prøve</Node>,
    CTA_LINK: <Node uri={`${pageName}/steps/step-2/cta-link`}>/clinics</Node>,
  },
  {
    TITLE: <Node uri={`${pageName}/steps/step-3/title`}>Ditt resultat får du online</Node>,
    CONTENT: (
      <Node uri={`${pageName}/steps/step-3/content.md`}>{md`
        Med en gang ditt svar er klart så får du en SMS. Logg inn med din BankID og få ditt NIPT resultat. De som behøver videre hjelp passer vi veldig godt på.
      `}</Node>
    ),
    CTA_TEXT: <Node uri={`${pageName}/steps/step-3/cta-text`}>Logg inn med BankID</Node>,
    CTA_LINK: <Node uri={`${pageName}/steps/step-3/cta-link`}>/#login</Node>,
  },
];

const NODES = {
  META_TITLE: <Node uri={`${pageName}/meta/title`}>NIPT, fosterdiagnostikk for gravide</Node>,
  META_DESCRIPTION: (
    <Node uri={`${pageName}/meta/description`}>Forstå NIPT og gjør valg som er rett for deg</Node>
  ),
  INTRO_TITLE: <Node uri={`${pageName}/intro/title`}>NIPT, fosterdiagnostikk for gravide</Node>,
  PHONE_IMAGE: <Node uri={`${pageName}/intro/phone.img`} />,
  INTRO_CONTENT: (
    <Node uri={`${pageName}/intro/content.md`}>NIPT ved 30 godkjente klinikker i Norge.</Node>
  ),
  SEARCH_TIMESLOTS_TITLE: (
    <Node uri={`${pageName}/intro/search-timeslots-title`}>Bestill time for NIPT</Node>
  ),
  HEADER_TITLE: (
    <Node uri={`${pageName}/header/title`}>Hvordan fungerer det å book time hos Edda Booking?</Node>
  ),
  HEADER_CTA: <Node uri={`${pageName}/header/cta`}>Kom i gang med NIPT-skolen</Node>,
  HEADER_CTA_LINK: <Node uri={`${pageName}/header/cta/link`}>/about-nipt</Node>,
  CLINICS_CTA: <Node uri={`${pageName}/clinics/cta`}>Book tid for NIPT-prøve</Node>,
  CLINICS_URL: <Node uri={`${pageName}/clinics/url`}>/nipt-book/forste-ledige-time</Node>,
  HEADER_CONTENT: (
    <Node uri={`${pageName}/header/content.md`}>
      {md`
        Enkel timebestilling uten kostnad. Betaling gjøres på klinikken. Du kan avbestille din time inntill 24t før ankomst klinikk. Kontakt oss i chatten om du har spørsmål!
      `}
    </Node>
  ),
  EXPLANATION_TITLE: <Node uri={`${pageName}/nipt-explained/title`}>Hva er NIPT?</Node>,
  EXPLANATION_POSTER: <Node uri={`${pageName}/nipt-explained/poster.img`} />,
  EXPLANATION_POINTS: (
    <Node uri={`${pageName}/points.md`}>{md`
- Test for trisomi 13, 18, & 21
- Fra uke 10+0
- IVF graviditeter
- Singel og tvilling graviditeter
    `}</Node>
  ),
  EXPLANATION_VIDEO_POSTER: <Node uri={`${pageName}/nipt-explained/video/poster.img`} />,
  EXPLANATION_VIDEO_URL: (
    <Node uri={`${pageName}/nipt-explained/video/url`}>
      https://player.vimeo.com/video/546946941?autoplay=1&title=0&byline=0&portrait=0
    </Node>
  ),

  EXPLANATION_CONTENT: (
    <Node uri={`${pageName}/nipt-explained/content.md`}>{md`
      NIPT er en forkortelse for Non Invasive Prenatal Test.

      En vanlig blodprøve tas i armen på den gravide kvinnen uten risiko for fosteret. ​
      NIPT brukes fordi den er pålitelig innen genetisk fostersdiagnostikk.
    `}</Node>
  ),
  SERVICES_EXPLANATION_TITLE: (
    <Node uri={`${pageName}/services-explained/title`}>Gynekologi og ultralyd</Node>
  ),
  SERVICES_EXPLANATION_POSTER: <Node uri={`${pageName}/services-explained/poster.img`} />,
  SERVICES_EXPLANATION_CONTENT: (
    <Node uri={`${pageName}/services-explained/content.md`}>{md`
      Ønsker du en samtale med en spesialist i gynekologi eller en ultralyd i forbindelse med graviditet? Lurer du på en cellprøver, fertilitet, menstruasjonssmerter eller lignende. Gynekologer og jordmødre jobber med kvinnehelse.

      Du kan enkelt bestille tid til en spesialist i booking over.
    `}</Node>
  ),
  ABOUT_TITLE: <Node uri={`${pageName}/about/title`}>Vi er Edda labs</Node>,
  ABOUT_CONTENT: (
    <Node uri={`${pageName}/about/content.md`}>
      Vi jobber for at du som gravid kvinne skal få forskningsbasert informasjon, slik at du kan
      gjøre et informert valg. Eksperter fra Skandinavia innen gynekologi, fostermedisin,
      neonatologi, barnemedisin, genetikk og etikk står bak EddaLabs.
    </Node>
  ),
  ABOUT_LINK: <Node uri={`${pageName}/about/link`}>Les mer om oss</Node>,
  ABOUT_URL: <Node uri={`${pageName}/about/url`}>/om-oss</Node>,
  PROXIMITY_TITLE: <Node uri={`${pageName}/proximity/title`}>Vi finns nære deg</Node>,
  PROXIMITY_CONTENT: (
    <Node uri={`${pageName}/proximity/content.md`}>
      Vi jobber med dyktige leger over hele landet. Velg en klinikk nære deg for ultralyd og
      prøvetaking.
    </Node>
  ),
  SECURITY_TITLE: <Node uri={`${pageName}/security/title`}>Sikker datahåndtering via BankId</Node>,
  SECURITY_CONTENT: (
    <Node uri={`${pageName}/security/content.md`}>
      All informasjon om deg behandles i henhold til Norsk og Svensk lov. Våre analyser gjøres i
      Sverige. Vi henter ikke ut genetisk data utover det du bestiller. Vi sender ikke dine prøver
      til USA, Storbritania, Kina eller andre steder i verden.
    </Node>
  ),
  STEPS_TITLE: <Node uri={`${pageName}/steps/title`} />,
  SECURITY_CTA: <Node uri={`${pageName}/security/cta`}>Logg in med BankID</Node>,
  PHONE_TITLE: <Node uri={`${pageName}/phone/title`}>Vil du heller prate?</Node>,
  PHONE_CONTENT: (
    <Node uri={`${pageName}/phone/content.md`}>
      Alle pasienter gjennomgår veileding på telefon. Gjør NIPT-skolen før samtale. Send oss
      deretter en email på admin@eddalabs.com så finner vi en tid. Skriv på engelsk da ikke alle hos
      oss snakker norsk.
    </Node>
  ),
  PHONE_EMAIL_ADDRESS: <Node uri={`${pageName}/phone/email`}>admin@addalabs.com</Node>,
  PHONE_CTA: <Node uri={`${pageName}/phone/cta`}>Book en telefontid</Node>,
  FAQ_TITLE: <Node uri={`${pageName}/title`}>Vanlige spørsmål</Node>,
  CONTACT_TITLE: (
    <Node uri={`${pageName}/footer-title`}>Har du inte fått svar på ditt spørsmål?</Node>
  ),
  CONTACT_CTA: <Node uri={`${pageName}/footer-cta`}>Kontakt oss: admin@eddalabs.com</Node>,
  CONTACT_CTA_EMAIL_ADRESS: (
    <Node uri={`${pageName}/footer-cta-email-address`}>admin@eddalabs.com</Node>
  ),
};

export default NODES;
