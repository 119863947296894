import React from 'react';
import cx from 'classnames';

import WithColor from '../../hocs/WithColor';
import styles from './Block.module.css';

export interface BlockProps {
  className?: string;
  component?: React.ElementType;
  padding?: 'none' | 'top-only' | 'bottom-only' | 'bottom-extra' | 'top-extra' | 'extra' | 'around';
}

const Block: React.FC<BlockProps> = ({
  className,
  component: Component = 'div',
  padding,
  children,
  ...rest
}) => {
  return (
    <Component
      className={cx(
        styles.root,
        {
          [styles.noPadding]: padding === 'none',
          [styles.noPaddingBottom]: padding === 'top-only',
          [styles.noPaddingTop]: padding === 'bottom-only',
          [styles.extraBottom]: padding === 'bottom-extra',
          [styles.extraTop]: padding === 'top-extra',
          [styles.extra]: padding === 'extra',
          [styles.around]: padding === 'around',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default WithColor(Block, { background: 'transparent' });
