import React, { ReactElement } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import styles from './PlaceholderField.module.css';

interface PlaceholderProps extends FieldProps<never, never> {
  className?: string;
  placeholder: ReactElement;
}

/**
 * Placeholder for use with FinalForm. Replaces the Field component
 *
 * Example usage:
 * <PlaceholderField name={""} render={Input}/>
 *
 *
 * @param className "className"
 * @param placeholder
 * @param field
 * @param props
 */

const PlaceholderField: React.FC<PlaceholderProps> = ({
  className,
  placeholder,
  field: RenderField,
  ...props
}) => (
  <Field {...props}>
    {({ input, meta, ...rest }: FieldRenderProps<never>) => {
      return (
        <div className={cx([styles.container, className])}>
          {React.cloneElement(placeholder, {
            edit: false,
            render: state => {
              if (state.type === 'success') {
                return (
                  <RenderField input={input} meta={meta} {...rest} placeholder={state.content} />
                );
              }
              return null;
            },
          })}
        </div>
      );
    }}
  </Field>
);

export default PlaceholderField;
